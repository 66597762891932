import Vulgar from "./Vulgar";
import Sentiment from "./Sentiment";

export default class Note {
    public static fromData(data: any) {
        let sentiment, vulgar;

        if (!data) {
            throw new Error("Note.fromData requires a data object");
        }
        
        if (!data.text) {
            throw new Error("Note.fromData requires data.text");
        }

        if (data.sentiment) {
            sentiment = Sentiment.fromData(data.sentiment);
        }

        if (data.vulgar) {
            vulgar = Vulgar.fromData(data.vulgar);
        }

        return new Note(
            data.text,
            sentiment,
            vulgar,
        );
    }

    constructor(
        public text: string,
        public readonly sentiment?: Sentiment,
        public readonly vulgar?: Vulgar,
    ) {
        if (sentiment && (sentiment.score < -1 || sentiment.score > 1)) {
            throw new Error(`Note.sentiment.score (${sentiment}) out of range (-1,1)`);
        }
    }
}
