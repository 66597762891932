import * as _ from "lodash";

export default class ScheduledMessage {
    public static fromData(data: any): ScheduledMessage {
        if (!data) {
            throw new Error("ScheduledMessage.fromData requires data");
        }

        if (!_.isString(data.id)) {
            throw new Error("ScheduledMessage.fromData data.id must be a string");
        }

        if (!_.isUndefined(data.list_id) && !_.isString(data.list_id)) {
            throw new Error("ScheduledMessage.fromData data.list_id should be a number");
        }

        if (!_.isUndefined(data.create_time) && !_.isString(data.create_time)) {
            throw new Error("ScheduledMessage.fromData data.create_time should be a string");
        }

        if (!_.isUndefined(data.go_time) && !_.isString(data.go_time)) {
            throw new Error("ScheduledMessage.fromData data.go_time should be a string");
        }

        if (!_.isUndefined(data.content) && !_.isString(data.content)) {
            throw new Error("ScheduledMessage.fromData data.content should be a string");
        }

        return new ScheduledMessage(
            data.id,
            data.list_id,
            data.create_time && new Date(data.create_time),
            data.go_time && new Date(data.go_time),
            data.content,
        );
    }

    constructor (
        public id: string,
        public list_id?: string,
        public create_time?: Date,
        public go_time?: Date,
        public content?: string,
    ) {}
}
