import * as _ from "lodash";

import Note from "./Note";
import Img from "./Img";

const nameRX = /^hosts\/(\d+)\/galleries\/(\d+)\/guests\/(\d+)\/tacs\/(\d+)$/

export default class Tac {
    public static is(name: string): boolean {
        return nameRX.test(name);
    }

    public static fromData(data: any) {
        let image, note;

        if (!data.name) {
            throw new Error("Tac requires name");
        }
        if (!_.isNumber(data.id)) {
            data.id = tacID(data.name);
        }
        if (!data.state) {
            data.state = "INHERIT";
        }
        if (data.state === 0) {
            data.state = "INHERIT";
        }
        if (data.state === 1) {
            data.state = "APPROVED";
        }
        if (data.state === 2) {
            data.state = "DELETED";
        }
        if (data.image) {
            image = Img.fromData(data.image);
        }
        if (data.note) {
            note = Note.fromData(data.note);
        }

        return new Tac(
            data.id,
            data.name,
            data.version,
            data.state,
            image,
            note,
        );
    }

    public image?: Img;
    public note?: Note;

    constructor(
        public id: number,
        public name: string,
        public version: number,
        public state: string,
        image?: Img,
        note?: Note,
    ) {
        if (!nameRX.test(name)) {
            throw new Error(`${name} is not a tac`);
        }
        if (!image && !note) {
            throw new Error("Tac.image or Tac.note is required");
        }

        if (image) this.image = image;
        if (note) this.note = note;
    }

    public patch(data: any) {
        if (data.image) {
            this.image = _.assign(this.image, data.image);
        }
        if (data.note) {
            this.note = _.assign(this.note, data.note);
        }

        return this;
    }

    public guest(): string {
        const parts = this.name.match(nameRX);

        return `hosts/${parts[1]}/galleries/${parts[2]}/guests/${parts[3]}`;
    }

    public guestID(): number {
        const parts = this.name.match(nameRX);

        return parseInt(parts[3], 10);
    }
}

function tacID(name: string): number {
        const parts = name.match(nameRX);

        return parseInt(parts[4], 10);
}
