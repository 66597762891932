import * as _ from "lodash";
import Tac from "./Tac";

export default class Guest {
    public static is(name: string): boolean {
        return /^hosts\/\d+\/galleries\/\d+\/guests\/\d+$/.test(name);
    }

    public static fromData(data: any): Guest {
        let tacs;

        if (!data) {
            throw new Error("Guest.fromData requires data");
        }

        if (!_.isString(data.name)) {
            throw new Error("Guest.fromData data.name must be a string");
        }
        if (!_.isNumber(data.id)) {
            data.id = guestID(data.name);
        }

        if (!_.isUndefined && !_.isBoolean(data.blocked)) {
            throw new Error("Guest.fromData data.blocked should be a boolean");
        }

        if (!_.isUndefined(data.full_name) && !_.isString(data.full_name)) {
            throw new Error("Guest.fromData data.full_name should be a string");
        }

        if (!_.isUndefined(data.phone) && !_.isString(data.phone)) {
            throw new Error("Guest.fromData data.phone should be a string");
        }

        if (!_.isUndefined(data.email) && !_.isString(data.email)) {
            throw new Error("Guest.fromData data.email should be a string");
        }

        if (data.tacs) {
            tacs = _.map(data.tacs, Tac.fromData);
        }

        return new Guest(
            data.name,
            !!data.blocked,
            data.full_name,
            data.phone,
            data.email,
            tacs,
        );
    }

    public full_name?: string;
    public phone?: string;
    public email?: string;
    public tacs?: Tac[];
    // For tests. Browser sends as cookie automatically.
    public token?: string;
    public version: number;

    constructor (
        public name: string,
        public blocked: boolean,
        full_name?: string,
        phone?: string,
        email?: string,
        tacs?: Tac[],
    ) {
        if (!_.isUndefined(full_name)) this.full_name = full_name;
        if (!_.isUndefined(phone)) this.phone = phone;
        if (!_.isUndefined(email)) this.email = email;
        if (tacs) this.tacs = tacs;
    }

    public patch(data: any) {
        this.version = 1; // deprecated
        if (_.has(data, "blocked")) {
            this.blocked = data.blocked;
        }
        if (_.has(data, "full_name")) {
            this.full_name = data.full_name;
        }
        if (_.has(data, "phone")) {
            this.phone = data.phone;
        }
        if (_.has(data, "email")) {
            this.email = data.email;
        }

        return this;
    }

    public prettyPhone(): string {
        if (!this.phone) return "";

        const parts = this.phone.match(/^\+1(\d{3})(\d{3})(\d{4})$/);

        if (!parts) return this.phone;

        return `(${parts[1]}) ${parts[2]}-${parts[3]}`
    }

    public galleryID(): number {
        const rx = /hosts\/\d+\/galleries\/(\d+)\/guests\/\d+$/;

        const matches = rx.exec(this.name);
        if (!matches || matches.length < 2) {
            throw new Error(`Cannot parse gallery ID from ${this.name}`)
        }
        return parseInt(matches[1]);
    }
}

function guestID(name: string): number {
        const nameRX = /hosts\/\d+\/galleries\/\d+\/guests\/(\d+)$/;
        const parts = name.match(nameRX);

        return parseInt(parts[1], 10);
}
