import * as _ from "lodash";

export default class Match {
    public static fromData(data: any) {
        if (!data) {
            throw new Error("Match data is required");
        }

        if (!_.isUndefined(data.term) && !_.isString(data.term)) {
            throw new Error("Match.term should be a string");
        }

        if (!_.isUndefined(data.start) && !_.isNumber(data.start)) {
            throw new Error("Match.start should be a number");
        }

        if (!_.isUndefined(data.end) && !_.isNumber(data.end)) {
            throw new Error("Match.end should be a number");
        }

        return new Match(
            data.term || "",
            data.start || 0,
            data.end || 0,
        );
    }

    constructor(
        public readonly term: string,
        public readonly start: number,
        public readonly end: number,
    ) {}
}
