import "../styles/public.css";
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import Script from "next/script";
import { PubNub } from '../api/Realtime';
import API from '../api';
import World from '../world';

const pubNubSubKey = process.env.NEXT_PUBLIC_PUB_NUB_SUB_KEY;
const pn = new PubNub(pubNubSubKey);
const api = new API(pn);
const world = new World(api, {});

// This default export is required in a new `pages/_app.js` file.
export default function MyApp({ Component, pageProps }) {
    const router = useRouter();

	useEffect(() => {
        (window as any).fbq('track', 'PageView')

		const handleRouteChange = () => {
            (window as any).fbq('track', 'PageView')
			world.logAd("PageView");
		}
		router.events.on('routeChangeComplete', handleRouteChange)
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange)
		}
	}, [router.events])

	useEffect(() => {
		if (typeof window !== "undefined") {
			const parsedQS = new URLSearchParams(location.search);
			const c = parsedQS.get("c");
			if (!c) {
				return;
			}
			if (!localStorage.c) {
				localStorage.c = c;
				return;
			}
			let have = localStorage.c.split(",");
			for (var i = 0; i < have.length; i++) {
				if (have[i] == c) {
					return;
				}
			}
			have.push(c);
			localStorage.c = have.join(",");
		}
	});

    return ( 
        <>
            <Head>
                <meta name="author" content="TacBoard LLC" />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-H63410KF1N"></script>
            </Head>

            <Script
            dangerouslySetInnerHTML={{
                __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', 'G-H63410KF1N');
                  gtag('config', 'AW-10896578733');
               `,
            }}
            />

            <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', 1857456867824784);
                `,
            }}
            />

	        <Component {...pageProps} />
        </>
    );
}
