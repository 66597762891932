import * as _ from "lodash";

export default class Archive {
    public static fromData(data: any): Archive {

        if (!data) {
            throw new Error("Archive.fromData requires data");
        }

        if (!_.isString(data.name)) {
            throw new Error("Archive.fromData data.name must be a string");
        }

        if (!_.isString(data.url)) {
            throw new Error("Archive.fromData data.url must be a string");
        }

        return new Archive(
          data.name,
          data.url,
        );
    }

    constructor (
        public name: string,
        public url: string,
    ) {}
}
