import * as _ from "lodash";

import Gallery from "./Gallery";

const nameRX = /^hosts\/(\d+)$/

export default class Host {
    public auth?: string;

    public static is(name: string): boolean {
        return /^hosts\/\d+$/.test(name);
    }

    public static fromData(data: any): Host {
        let galleries;

        if (!data) {
            throw new Error("Host.fromData requires data");
        }

        if (!_.isNumber(data.id) && !_.isString(data.name)) {
            throw new Error("Host.fromData data.id must be a number or data.name must be a string");
        }

        if (!_.isString(data.email)) {
            throw new Error("Host.fromData data.email must be a string");
        }

        if (!_.isString(data.full_name)) {
            data.full_name = "";
        }

        if (!_.isUndefined(data.password) && !_.isString(data.password)) {
            throw new Error("Host.fromData data.password should be a string");
        }

        if (!_.isUndefined(data.stripe_id) && !_.isString(data.stripe_id)) {
            throw new Error("Host.fromData data.stripe_id should be a string");
        }

        if (!_.isUndefined(data.shipping_address) && !_.isString(data.shipping_address)) {
            throw new Error("Host.fromData data.shipping_address should be a string");
        }

        if (!_.isUndefined(data.discount) && !_.isNumber(data.discount)) {
            throw new Error("Host.fromData data.discount should be a string");
        }

        if (data.galleries) {
            galleries = _.map(data.galleries, Gallery.fromData);
        }

        if (!_.isUndefined(data.tour) && !_.isBoolean(data.tour)) {
            throw new Error("Host.fromData data.tour should be a boolean");
        }

        if (!_.isUndefined(data.has_tacced) && !_.isBoolean(data.has_tacced)) {
            throw new Error("Host.fromData data.has_tacced should be a boolean");
        }

        if (!_.isUndefined(data.has_edited_theme) && !_.isBoolean(data.has_edited_theme)) {
            throw new Error("Host.fromData data.has_edited_theme should be a boolean");
        }

        if (!_.isUndefined(data.has_edited_header) && !_.isBoolean(data.has_edited_header)) {
            throw new Error("Host.fromData data.has_edited_header should be a boolean");
        }

        if (!_.isUndefined(data.has_experienced_live) && !_.isBoolean(data.has_experienced_live)) {
            throw new Error("Host.fromData data.has_experienced_live should be a boolean");
        }

        if (!_.isUndefined(data.has_managed_queue) && !_.isBoolean(data.has_managed_queue)) {
            throw new Error("Host.fromData data.has_managed_queue should be a boolean");
        }

        return new Host(
            data.name || `hosts/${data.id}`,
            0,
            data.email,
            data.full_name,
            data.password,
            data.stripe_id,
            data.shipping_address,
            data.discount,
            galleries,
            data.tour,
            data.has_tacced,
            data.has_edited_theme,
            data.has_edited_header,
            data.has_experienced_live,
            data.has_managed_queue,
        )
    }

    public password?: string;
    public stripe_id?: string;
    public shipping_address?: string;
    public discount: number;
    public galleries?: Gallery[];
    public tour: boolean;
    public has_tacced: boolean;
    public has_edited_theme: boolean;
    public has_edited_header: boolean;
    public has_experienced_live: boolean;
    public has_managed_queue: boolean;

    constructor(
        public name: string,
        public version: number,
        public email: string,
        public full_name: string,
        password?: string,
        stripe_id?: string,
        shipping_address?: string,
        discount?: number,
        galleries?: Gallery[],
        tour?: boolean,
        has_tacced?: boolean,
        has_edited_theme?: boolean,
        has_edited_header?: boolean,
        has_experienced_live?: boolean,
        has_managed_queue?: boolean,
    ) {
        if (!_.isUndefined(password)) this.password = password;
        if (!_.isUndefined(stripe_id)) this.stripe_id = stripe_id;
        if (!_.isUndefined(shipping_address)) this.shipping_address = shipping_address;
        if (!_.isUndefined(discount)) {
            this.discount = discount;
        } else {
            this.discount = 0;
        }
        if (galleries) this.galleries = galleries;
        this.tour = !!tour;
        this.has_tacced = !!has_tacced;
        this.has_edited_theme = !!has_edited_theme;
        this.has_edited_header = !!has_edited_header;
        this.has_experienced_live = !!has_experienced_live;
        this.has_managed_queue = !!has_managed_queue;
    }

    public patch(data: any) {
        if (data.version && data.version > this.version) {
            this.version = data.version;
        }

        if (_.has(data, "full_name")) {
            this.full_name = data.full_name;
        }
        
        if (_.has(data, "discount")) {
            if (!_.isNumber(data.discount)) {
                throw new Error("Host patch discount should be a number");
            }
            this.discount = data.discount;
        }

        if (_.has(data, "shipping_address")) {
            if (!_.isString(data.shipping_address)) {
                throw new Error("Host patch shipping_address should be a string");
            }
        }

        return this;
    }

    public id(): number {
        const parts = this.name.match(nameRX);

        return parseInt(parts[1], 10);
    }
}
