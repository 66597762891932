import * as _ from "lodash";

import PN from "pubnub";

export type PatchHandler = (patch: any) => void;
export type Unsubber = () => void;

export interface Realtime {
    // returns a cancel function
    subscribe: (
        gallery_name: string,
        access_token: string,
        handle: PatchHandler,
    ) => Promise<Unsubber>;
}

export class PubNub {
    private client: Promise<any>;
    // connected channels according to PubNub
    private subscriptions: string[];
    // listeners for subscriptions change events
    private onSubscriptionsChanged: Array<() => void>;
    // listeners subscribed to a channel that is possibly connected and in subscriptions
    private handlers: {[key: string]: PatchHandler[]};

    constructor(
        pub_nub_sub_key: string,
    ) {
        this.subscriptions = [];
        this.onSubscriptionsChanged = [];
        this.handlers = {};

        this.client = new Promise((resolve, reject) => {
            const client = new PN({
                subscribeKey: pub_nub_sub_key,
                ssl: true,
            });

            resolve(client);

            client.addListener({
                message: (msg: any) => {
                    const handlers = this.handlers[msg.channel];
                    if (!handlers) {
                        return;
                    }

                    const data = msg.entry || msg.message;
                    const patch = typeof data === 'string' ? JSON.parse(data) : data;

                    handlers.forEach(h => h(patch));
                },

                // TODO metrics
                status: (e: any) => {
                    switch (e.category) {
                    case "PNNetworkUpCategory":
                        break;
                    case "PNNetworkDownCategory":
                        break;
                    case "PNNetworkIssuesCategory":
                        break;
                    case "PNReconnectedCategory":
                        break;
                    case "PNConnectedCategory":
                        this.subscriptions = e.subscribedChannels;
                        this.onSubscriptionsChanged.forEach(h => h());
                        break;
                    case "PNAccessDeniedCategory":
                        break;
                    case "PNMalformedResponseCategory":
                        break;
                    case "PNBadRequestCategory":
                        break;
                    case "PNDecryptionErrorCategory":
                        break;
                    case "PNTimeoutCategory":
                        break;
                    }
                }
            });
        });
    }

    public async subscribe(
        gallery_name: string,
        access_token: string,
        handler: PatchHandler,
    ): Promise<Unsubber> {
        const client = await this.client;

        // Assuming this is no-op if already subscribed.
        client.subscribe({
            channels: [access_token],
        });

        if (!this.handlers[access_token]) {
			this.handlers[access_token] = [];
        }

		this.handlers[access_token].push(handler);

        await this.subscribed(access_token);

        // cancel
        return () => {
            const handlers = this.handlers[access_token];
			if (!handlers) {
				return;
			}

			_.pull(handlers, handler);

			if (handlers.length === 0) {
                client.unsubscribe({
                    channels: [access_token],
                });
            }
        }
    }

    private async subscribed(channel: string) {
		if (_.includes(this.subscriptions, channel)) {
            return;
        }

        return new Promise((resolve) => {
            const h = () => {
				if (_.includes(this.subscriptions, channel)) {
                    resolve(undefined);
                }
				_.pull(this.onSubscriptionsChanged, h);
            };

			this.onSubscriptionsChanged.push(h);
        });
    }
}
