import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import * as querystring from "querystring";
import * as _ from "lodash";
import * as setCookie from "set-cookie-parser";

import Admin from "../types/Admin";
import Host from "../types/Host";
import Gallery from "../types/Gallery";
import Guest from "../types/Guest";
import List from "../types/List";
import ScheduledMessage from "../types/ScheduledMessage";
import Tac from "../types/Tac";
import Img from "../types/Img";
import Note from "../types/Note";
import Archive from "../types/Archive";
import { HostsQuery } from "../types/HostsQuery";

import { PatchHandler, Realtime, Unsubber } from "./Realtime"

export interface LoginRequest {
    email: string;
    password: string;
}

export interface ChangePasswordRequest {
    email: string;
    password: string;
    secret: string;
}

export interface CreateHostRequest {
    email: string;
    full_name: string;
    password: string;
    discount?: string;
    tour?: boolean;
}

export interface AddCoHostRequest {
    email: string;
}

export interface CoHost {
    email: string;
    host_id: string;
}

export interface CreateGalleryRequest {
    display_name: string;
    date: string;
}

export interface CreateGuestRequest {
    full_name?: string;
    phone?: string;
    email?: string;
}

export interface CreateListRequest {
    gallery_id: number;
    display_name: string;
    default_all_guests: boolean;
}

export interface CreateScheduledMessageRequest {
    list_id: string;
    go_time: Date;
    content: string;
}

export interface CreateTacRequest {
    image?: Img;
    note?: Note;
}

export interface GrantDiscountRequest {
    email: string;
    discount: number;
}

export interface APIErr {
    status: number;
    message: string;
}

export interface PaymentIntent {
    client_secret: string;
    price: number;
    amount: number;
    discount: number;
}

export type MaybeAPIErr = APIErr | null;

type MaybeAdmin = Admin | null;
type MaybeHost = Host | null;
export type MaybeGallery = Gallery | null;
export type MaybeGuest = Guest | null;
type MaybeList = List | null;
type MaybeScheduledMessage = ScheduledMessage | null;
type MaybeTac = Tac | null;
type MaybeArchive = Archive | null;

export type AdminResponse = [MaybeAdmin, MaybeAPIErr];
export type HostResponse = [MaybeHost, MaybeAPIErr];
export type GalleryResponse = [MaybeGallery, MaybeAPIErr];
export type GuestResponse = [MaybeGuest, MaybeAPIErr];
export type ListResponse = [MaybeList, MaybeAPIErr];
export type ScheduledMessageResponse = [MaybeScheduledMessage, MaybeAPIErr];
export type TacResponse = [MaybeTac, MaybeAPIErr];
export type ArchiveResponse = [MaybeArchive, MaybeAPIErr];

export default class Client {
    private axios: AxiosInstance;
    private realtime?: Realtime;
    private cookieJar?: any;

    constructor(
        realtime?: Realtime,
        origin?: string,
    ) {
        origin = origin || "";

        this.axios = axios.create({
            baseURL: origin + "/api/v2",
        });

        this.realtime = realtime;
        this.cookieJar = {};
    }

    public async subscribe(
        gallery_name: string,
        access_token: string,
        handle: PatchHandler,
    ): Promise<Unsubber> {
        return this.realtime.subscribe(
            gallery_name,
            access_token,
            handle,
        );
    }

	public async logAd(action: string): Promise<void> {
		const ad = localStorage.c;
		if (!ad) {
			return;
		}
		await this.POST("/log-ad", {
			ad: ad,
			path: window.location.pathname,
			action: action,
		});
	}

	public async loginAdmin(username: string, password: string): Promise<AdminResponse> {
        const [data, err] = await this.POST("/admins:login", {
            user: username,
            password: password,
        });

        if (err) {
            return [null, err];
        }
        return [Admin.fromData(data), null];
    }

    public async logout(): Promise<void> {
        await this.POST("/logout", null)
    }

    public async oauthAdmin(id_token: string): Promise<AdminResponse> {
        const [data, err] = await this.POST("/admins:oauth", {
            id_token,
        });
        
        if (err) {
            return [null, err];
        }

        return [Admin.fromData(data), null];
    }

    public async discountSelf(
        code: string,
        Authorization: string,
    ): Promise<MaybeAPIErr> {
        const resp = await this.POST(
            "/hosts/discount",
            { code },
            Authorization,
        );

        return resp[0]
    }

    public async grantDiscount(
        req: GrantDiscountRequest,
        Authorization: string,
    ): Promise<MaybeAPIErr> {
        const resp = await this.POST(
            "/hosts:discount",
            req,
            Authorization,
        );

        return resp[1];
    }

    public async login(req: LoginRequest): Promise<[Host | null, APIErr | null]> {
        const resp = await this.POST("/hosts:login", {
            email: req.email,
            password: req.password,
        });

        return hostResponse(resp);
    }

    public async resetPassword(email: string): Promise<MaybeAPIErr> {
        const resp = await this.POST("/host/reset", { email });

        return resp[1];
    }
 
    public async changePassword(req: ChangePasswordRequest): Promise<MaybeAPIErr> {
        const resp = await this.POST("/host/change-password", req);

        return resp[1];
    }

    public async facebookOAuth(token: string, tour: boolean): Promise<HostResponse> {
        const resp = await this.POST("/hosts:facebook-oauth", { token, tour });

        return hostResponse(resp);
    }

    public async register (req: CreateHostRequest): Promise<HostResponse> {
        const resp = await this.POST("/register", req);

        return hostResponse(resp);
    }

    public async convertGallery (
        gallery_name: string,
        product_id: string,
        stripe_token: string,
        price: number,
        Authorization: string,
    ): Promise<MaybeAPIErr> {
        const resp = await this.POST(
            `/${gallery_name}/convert`,
            {
                product_id,
                stripe_token,
                price,
            },
            Authorization,
        );

        return resp[1];
    }

    public async getPrice (): Promise<[number, MaybeAPIErr]> {
      const resp = await this.GET("/price")

      if (resp[1]) {
        return [0, resp[1]];
      }

      return [resp[0].price, null];
    }

    public async createPaymentIntent (
        Authorization: string,
    ): Promise<[PaymentIntent|null, MaybeAPIErr]> {
        const resp = await this.POST(
          `/create-payment-intent`,
          {},
          Authorization,
        );

        if (resp[1]) {
            return [null, resp[1]];
        }
        return [resp[0], null];
    }

    public async uploadURL(): Promise<[string|null, MaybeAPIErr]> {
        const resp = await this.POST(`/upload-url`, {});

        if (resp[1]) {
            return [null, resp[1]];
        }
        return [resp[0].url, null];
    }

    public async readSelfGuest(token: string): Promise<[MaybeGallery, MaybeGuest, MaybeAPIErr]> {
        const resp = await this.GET(`/read-self-guest/${token}`);
        if (resp[1]) {
            return [null, null, resp[1]];
        }
 
        return [
            resp[0].gallery ? Gallery.fromData(resp[0].gallery) : null,
            resp[0].guest ? Guest.fromData(resp[0].guest) : null,
            null,
        ];
    }

    public async createGallery (
        host_name: string,
        gallery: CreateGalleryRequest,
        payment_intent_id: string,
        Authorization: string,
    ): Promise<GalleryResponse> {
        const resp = await this.POST(
            `/${host_name}/galleries`,
            gallery,
            Authorization,
            { payment_intent_id },
        );

        return galleryResponse(resp);
    }

    public async openGallery (
        gallery_name: string,
        Authorization: string,
    ): Promise<GalleryResponse> {
        const resp = await this.POST(
            `/${gallery_name}/open`,
            {},
            Authorization,
        );

        return galleryResponse(resp);
    }

    public async createArchive(
        gallery_name: string,
        Authorization: string,
    ): Promise<ArchiveResponse> {
        const resp = await this.POST(
            `/${gallery_name}/archives`,
            {},
            Authorization,
        );

        return archiveResponse(resp);
    }

    public async allowFeatured(
        gallery_id: number,
        permission_granted: boolean,
        answers: string,
        Authorization: string,
    ): Promise<MaybeAPIErr> {
        const data = {
            gallery_id,
            permission_granted,
            answers,
        };
        const resp = await this.POST("/allow-featured", data, Authorization);

        return resp[1];
    }

    public async addCoHost(
        gallery_name: string,
        req: AddCoHostRequest,
        Authorization: string,
    ): Promise<GalleryResponse> {
        const resp = await this.POST(`/${gallery_name}/cohosts`, req, Authorization);
        return galleryResponse(resp);
    }

    public async listCoHosts(
        gallery_name: string,
        Authorization: string,
    ): Promise<[CoHost[], MaybeAPIErr]> {
        const [cohosts, err] = await this.GET(`/${gallery_name}/cohosts`, Authorization);

        if (err) {
            return [[], err]
        }

        return [cohosts, null]
    }

    public async removeCoHost(
        gallery_name: string,
        email: string,
        Authorization: string,
    ): Promise<MaybeAPIErr> {
        const err = await this.DELETE(`/${gallery_name}/cohosts/${email}`, Authorization);
        return err
    }

    public async createGuest(
        gallery_name: string,
        guest: CreateGuestRequest,
        Authorization: string,
    ): Promise<GuestResponse> {
        const [data, cookies, err] = await this.postCookie(`/${gallery_name}/guests`, guest, Authorization);

        _.assign(this.cookieJar, cookies);

        return guestResponse([data, err]);
    }

    public async createList(
        list: CreateListRequest,
        Authorization: string,
    ): Promise<ListResponse> {
        const resp = await this.POST(`/lists`, list, Authorization);

        return listResponse(resp);
    }

    public async createScheduledMessage(
        sm: CreateScheduledMessageRequest,
        Authorization: string,
    ): Promise<ListResponse> {
        const resp = await this.POST("/scheduled-messages", sm, Authorization);

        return scheduledMessageResponse(resp);
    }

    public async createTac(
        guest_name: string,
        tac: CreateTacRequest,
        galleryID: number,
    ): Promise<TacResponse> {
        if (typeof window === "undefined") {
            const cookieName = `guest-${galleryID}`;
            const cookieObj = this.cookieJar[cookieName];
            if (!cookieObj) {
                return [
                    null,
                    {
                        status: 401,
                        message: "Unauthenticated",
                    },
                ];
            }
            const cookie = `${cookieName}=${cookieObj.value}`;

            const [data, setCookie, err] = await this.postCookie(`/${guest_name}/tacs`, tac, "", cookie);

            return tacResponse([data, err]);
        }
        const [data, err] = await this.POST(`/${guest_name}/tacs`, tac);

        return tacResponse([data, err]);
    }

    // Only for testing that API returns 401
    public async createTac401(
        guest_name: string,
        tac: CreateTacRequest,
    ): Promise<TacResponse> {
        const result = await this.POST(`/${guest_name}/tacs`, tac);

        return tacResponse(result);
    }

    // Only for testing that API returns 403
    public async createTac403(
        guest_name: string,
        tac: CreateTacRequest,
        gallery_id: string,
        forbidden_gallery_id: string,
    ): Promise<TacResponse> {
        const cookieName = `guest-${gallery_id}`;
        const cookieObj = this.cookieJar[cookieName];
        const cookie = `guest-${forbidden_gallery_id}=${cookieObj.value}`

        const [data, setCookie, err] = await this.postCookie(`/${guest_name}/tacs`, tac, "", cookie);

        return tacResponse([data, err]);
    }

    public async getHost(
        name: string,
        Authorization: string,
        version?: number,
    ): Promise<HostResponse> {
        const resp = await this.GET(`/${name}`, Authorization, {
            version: version || 0,
        });

        return hostResponse(resp);
    }

    public async getGallery(
        name: string,
        Authorization?: string,
        access_token?: string,
        version?: number,
    ): Promise<GalleryResponse> {
        const resp = await this.GET(`/${name}`, Authorization, {
            access_token,
            version: version || 0,
        });

        return galleryResponse(resp);
    }

    public async getGuest(
        name: string,
        Authorization: string,
        version?: number,
    ): Promise<GuestResponse> {
        const resp = await this.GET(`/${name}`, Authorization, {
            version: version || 0,
        });

        return guestResponse(resp);
    }

    public async getTac(
        name: string,
        Authorization: string,
        version?: number,
    ): Promise<TacResponse> {
        const resp = await this.GET(`/${name}`, Authorization, {
            version: version || 0,
        });

        return tacResponse(resp);
    }

    public async updateHost(
        name: string,
        Authorization: string,
        patch: any,
    ): Promise<HostResponse> {
        const resp = await this.PATCH(`/${name}`, patch, Authorization, {
            "update_mask.paths": _.keys(patch),
        });

        return hostResponse(resp);
    }

    public async updateGallery(
        name: string,
        Authorization: string,
        patch: any,
    ): Promise<GalleryResponse> {
        const changes = _.pick(patch, ["display_name", "theme", "avatar", "date"]);

        const resp = await this.PATCH(`/${name}`, changes, Authorization, {
            "update_mask.paths": _.keys(changes),
        });

        return galleryResponse(resp);
    }

    public async updateGuest(
        name: string,
        Authorization: string,
        patch: any,
    ): Promise<GuestResponse> {
        const changes = _.pick(patch, ["full_name", "phone", "email", "blocked"]);

        const resp = await this.PATCH(`/${name}`, changes, Authorization, {
            "update_mask.paths": _.keys(changes),
        });

        return guestResponse(resp);
    }

    public async updateTac(
        name: string,
        Authorization: string,
        patch: any,
    ): Promise<TacResponse> {
        const changes = _.pick(patch, ["state"]);

        const resp = await this.PATCH(`/${name}`, changes, Authorization, {
            "update_mask.paths": _.keys(changes),
        });

        return tacResponse(resp);
    }

    public async listHosts(
        page_size:  number,
        Authorization: string,
        page_token?: string,
    ): Promise<[Host[], string, APIErr | null]> {
        const [data, err] = await this.GET("/hosts", Authorization, {
            page_size,
            page_token,
        });

        if (err) {
            return [[], "", err];
        }

        if (!data.hosts) {
            return [[], "", null];
        }

        return [_.map(data.hosts, Host.fromData), data.next_page_token || "", null]
    }

    public async searchHosts(
        Authorization: string,
        query: HostsQuery,
    ): Promise<[Host[], string, APIErr | null]> {
        const [data, err] = await this.GET("/hosts:search", Authorization, query);

        if (err) {
            return [[], "", err];
        }

        if (!data.hosts) {
            return [[], "", null];
        }

        const hosts = _.map(data.hosts, Host.fromData);
        return [hosts, data.next_page_token || "", null]
    }

    public async listGalleries(
        host_name: string,
        page_size: number,
        Authorization: string,
        page_token?: string,
    ): Promise<[Gallery[], string, APIErr | null]> {
        const [data, err] = await this.GET(`${host_name}/galleries`, Authorization, {
            page_size,
            page_token,
        });

        if (err) {
            return [[], "", err];
        }

        if (!data.galleries) {
            return [[], "", null];
        }

        return [
            _.map(data.galleries, Gallery.fromData),
            data.next_page_token || "",
            null,
        ];
    }

    public async listGuests(
        gallery_name: string,
        page_size: number,
        Authorization: string,
        page_token?: string,
    ): Promise<[Guest[], string, APIErr | null]> {
        const [data, err] = await this.GET(`${gallery_name}/guests`, Authorization, {
            page_size,
            page_token,
        });

        if (err) {
            return [[], "", err];
        }

        if (!data.guests) {
            return [[], "", null];
        }

        return [
            _.map(data.guests, Guest.fromData),
            data.next_page_token || "",
            null,
        ];
    }

    public async listLists(
        gallery_id: number,
        Authorization: string,
    ): Promise<[List[] | null, MaybeAPIErr]> {
        const [data, err] = await this.GET("/lists", Authorization, {
            gallery_id,
        });
        if (err) {
            return [null, err];
        }

        if (!data.lists) {
            return [[], null];
        }

        return [
            _.map(data.lists, List.fromData),
            null,
        ];
    }

    public async listTacs(
        guest_name: string,
        page_size: number,
        Authorization: string,
        page_token?: string,
    ): Promise<[Tac[], string, MaybeAPIErr]> {
        const [data, err] = await this.GET(`${guest_name}/tacs`, Authorization, {
            page_size,
            page_token,
        });

        if (err) {
            return [[], "", err];
        }

        if (!data.tacs) {
            return [[], "", null];
        }

        return [
            _.map(data.tacs, Tac.fromData),
            data.next_page_token || "",
            null,
        ];
    }

    public async deleteHost(
        host_name: string,
        Authorization: string,
    ): Promise<APIErr | null> {
        return await this.DELETE(`/${host_name}`, Authorization);
    }

    public async deleteGallery(
        gallery_name: string,
        Authorization: string,
    ): Promise<MaybeAPIErr> {
        return await this.DELETE(`/${gallery_name}`, Authorization);
    }

    public async deleteGuest(
        guest_name: string,
        Authorization: string,
    ): Promise<MaybeAPIErr> {
        return await this.DELETE(`/${guest_name}`, Authorization);
    }

    public async fillPool(
        quantity: number,
        Authorization: string,
    ): Promise<[string[], MaybeAPIErr]> {
        const [data, err] = await this.POST(`/pool:fill`, { quantity }, Authorization);
        if (err) {
            return [[], err];
        }

        return [data.phones, null];
    }

    public async listPool(
        Authorization: string,
    ): Promise<[string[], MaybeAPIErr]> {
        const [data, err] = await this.GET(`/pool`, Authorization);
        if (err) {
            return [[], err];
        }

        return [data.phones, null];
    }

    public async deleteNumber(
        number_name: string,
        Authorization: string,
    ): Promise<MaybeAPIErr> {
        return await this.DELETE(`/${number_name}`, Authorization);
    }

    public async releaseNumber(
        number_name: string,
        Authorization: string,
    ): Promise<MaybeAPIErr> {
        const resp = await this.POST(`/${number_name}/release`, null, Authorization);

        return resp[1];
    }

    // For testing in node. Browsers set the cookie automaticaly.
    private async postCookie(path: string, body: any, Authorization?: string, Cookie?: string, query?: any): Promise<[any, any, APIErr | null]> {
        const config: AxiosRequestConfig = {
            headers: {},
        };

        if (Authorization) {
            config.headers.Authorization = Authorization;
        }
        if (Cookie) {
            config.headers.Cookie = Cookie;
        }

        if (query) {
            path += "?" + querystring.stringify(query);
        }

        try {
            const { data, headers } = await this.axios.post(path, body, config);
            const cookieOpts = {
                decodeValues: true,
                map: true,
            };

            return [data, setCookie.parse(headers["set-cookie"], cookieOpts), null];
        } catch (err) {
            return [null, null, apiErr(err)];
        }
    }

    private async POST(path: string, body: any, Authorization?: string, query?: any): Promise<[any, APIErr | null]> {
        const config: AxiosRequestConfig = {};

        if (Authorization) {
            config.headers = { Authorization };
        }

        if (query) {
            path += "?" + querystring.stringify(query);
        }

        try {
            const { data, headers } = await this.axios.post(path, body, config);
            return [data, null];
        } catch (err) {
            return [null, apiErr(err)];
        }
    }

    private async PATCH(path: string, body: any, Authorization: string, query: any): Promise<Response> {
        const headers = { Authorization };

        if (query) {
            path += "?" + querystring.stringify(query);
        }

        try {
            const { data } = await this.axios.patch(path, body, { headers });

            return [data, null];
        } catch (err) {
            return [null, apiErr(err)];
        }
    }

    private async DELETE(path: string, Authorization: string): Promise<MaybeAPIErr> {
        const headers = { Authorization };

        try {
            await this.axios.delete(path, { headers });

            return null;
        } catch (err) {
            return apiErr(err);
        }
    }

    private async GET(path: string, Authorization?: string, query?: any): Promise<[any, APIErr | null]> {
        const config: AxiosRequestConfig = {};

        if (Authorization) {
            config.headers = { Authorization };
        }

        if (query) {
            path += "?" + querystring.stringify(query);
        }

        try {
            const { data } = await this.axios.get(path, config);

            return [data, null];
        } catch (err) {
            return [null, apiErr(err)];
        }
    }
};

function apiErr(err: any): APIErr|null {
    if (!err) {
        return null;
    }

    if (err.response) {
        return {
            status: err.response.status,
            message: err.response.data.error || err.response.data.message,
        };
    }

    throw err;
}

type Response = [any, MaybeAPIErr];

const hostResponse = (resp: Response): HostResponse => {
    if (resp[1]) {
        return [null, resp[1]];
    }
    return [Host.fromData(resp[0]), null];
}

const galleryResponse = (resp: Response): GalleryResponse => {
    if (resp[1]) {
        return [null, resp[1]];
    }
    return [Gallery.fromData(resp[0]), null];
}

const guestResponse = (resp: Response): GuestResponse => {
    if (resp[1]) {
        return [null, resp[1]];
    }
    return [Guest.fromData(resp[0]), null];
}

const listResponse = (resp: Response): ListResponse => {
    if (resp[1]) {
        return [null, resp[1]];
    }
    return [List.fromData(resp[0]), null];
}

const scheduledMessageResponse = (resp: Response): ScheduledMessageResponse => {
    if (resp[1]) {
        return [null, resp[1]];
    }
    return [ScheduledMessage.fromData(resp[0]), null];
}

const tacResponse = (resp: Response): TacResponse => {
    if (resp[1]) {
        return [null, resp[1]];
    }
    return [Tac.fromData(resp[0]), null];
}

const archiveResponse = (resp: Response): ArchiveResponse => {
    if (resp[1]) {
        return [null, resp[1]];
    }
    return [Archive.fromData(resp[0]), null];
}
