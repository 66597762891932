import * as _ from "lodash";

export default class Admin {
    public static fromData(data: any) {
        if (!data) {
            throw new Error("Admin.fromData requires data");
        }

        if (!_.isString(data.name)) {
            throw new Error("Admin.fromData requires data.name");
        }

        if (!_.isString(data.password)) {
            throw new Error("Admin.fromData requires data.password");
        }

        return new Admin(
            data.name,
            data.password,
        );
    }

    constructor(
        public name: string,
        public password: string,
    ) {}
}
