import * as _ from "lodash";
import Guest from "./Guest";

const nameRX = /^hosts\/(\d+)\/galleries\/(\d+)$/

export default class Gallery {
    public static is(name: string): boolean {
        return /^hosts\/\d+\/galleries\/\d+$/.test(name);
    }

    public static fromData(data: any): Gallery {
        let guests;
        let name = data.name;

        if (!data) {
            throw new Error("Gallery.fromData requires data");
        }

        if (!name) {
            if (!_.isNumber(data.host_id)) {
                throw new Error("Gallery.fromData data.host_id must be a number");
            }
            if (!_.isNumber(data.id)) {
                throw new Error("Gallery.fromData data.id must be a number");
            }

            name = `hosts/${data.host_id}/galleries/${data.id}`
        }

        if (!_.isString(data.display_name)) {
            data.display_name = "";
        }

        if (!_.isString(data.theme)) {
            throw new Error("Gallery.fromData data.theme must be a string");
        }

        if (!_.isString(data.avatar)) {
            throw new Error("Gallery.fromData data.avatar must be a string");
        }

        if (!_.isString(data.date)) {
            throw new Error("Gallery.fromData data.date must be a string");
        }

        if (!_.isString(data.access_token)) {
            throw new Error("Gallery.fromData data.access_token must be a string");
        }

        if (!_.isUndefined(data.phone) && !_.isString(data.phone)) {
            throw new Error("Gallery.fromData data.phone should be a string");
        }

        if (!_.isUndefined(data.email) && !_.isString(data.email)) {
            throw new Error("Gallery.fromData data.email should be a string");
        }

        if (data.guests) {
            guests = _.map(data.guests, Guest.fromData);
        }

        if (!_.isUndefined(data.moderation) && !_.isString(data.moderation)) {
            throw new Error("Gallery.fromData data.moderation should be a string");
        }

        return new Gallery(
            name,
            data.display_name,
            data.theme,
            data.avatar,
            data.date,
            data.access_token,
            data.phone,
            data.email,
            guests,
            data.moderation,
        );
    }

    public phone?: string;
    public email?: string;
    public hashtag?: string;
    public guests?: Guest[];
    public moderation?: string;

    constructor(
        public name: string,
        public display_name: string,
        public theme: string,
        public avatar: string,
        public date: string,
        public access_token: string,
        phone?: string,
        email?: string,
        guests?: Guest[],
        moderation?: string,
    ) {
        if (!_.isUndefined(phone)) this.phone = phone;
        if (!_.isUndefined(email)) this.email = email;
        if (guests) this.guests = guests;
        if (!_.isUndefined(moderation)) this.moderation = moderation;
    }

    public patch(data: any) {
        if (data.display_name) {
            this.display_name = data.display_name;
        }
        if (data.theme) {
            this.theme = data.theme;
        }
        if (data.avatar) {
            this.avatar = data.avatar;
        }
        if (data.date) {
            this.date = data.date;
        }
        if (data.access_token) {
            this.date = data.access_token;
        }
        if (data.phone) {
            this.phone = data.phone;
        }
        if (data.email) {
            this.email = data.email;
        }
        if (data.hashtag) {
            this.hashtag = data.hashtag;
        }
        if (data.guests) {
            this.guests = data.guests;
        }

        return this;
    }

    public id(): number {
        const parts = this.name.match(nameRX);

        return parseInt(parts[2], 10);
    }

    public host(): string {
        const parts = this.name.match(nameRX);

        return `hosts/${parts[1]}`;
    }

    public hostID(): number {
        const parts = this.name.match(nameRX);

        return parseInt(parts[1], 10);
    }

    public prettyPhone(): string {
        if (!this.phone) return "";

        const parts = this.phone.match(/^\+1(\d{3})(\d{3})(\d{4})$/);

        if (!parts) return this.phone;

        return `(${parts[1]}) ${parts[2]}-${parts[3]}`
    }

    public isDefaultAvatar(): boolean {
        return this.avatar === "https://tacboard.com/img/default-board-header-bg.jpg";
    }

    public isDefaultDisplayName(host_full_name: string): boolean {
        return this.display_name === `The Wedding of ${host_full_name}`;
    }

    public isTrial(): boolean {
        return false;
    }
}
