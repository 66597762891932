import * as _ from "lodash";

export default class Rendition {
    public static fromData(data: any) {
        if (!data) {
            throw new Error("Rendition data is required");
        }

        if (!_.isString(data.url)) {
            throw new Error("Rendition.url must be a string");
        }

        if (!_.isNumber(data.width)) {
            throw new Error("Rendition.width must be a number");
        }

        if (!_.isNumber(data.height)) {
            throw new Error("Rendition.height must be a number");
        }

        if (!_.isString(data.content_type)) {
            throw new Error("Renditon.content_type must be a string");
        }

        /*
        if (!_.isNumber(data.size)) {
            throw new Error("Rendition.size must be a number");
        }
        */

        return new Rendition(
            data.url,
            data.width,
            data.height,
            data.content_type,
            data.size || 0,
        );
    }

    constructor(
        public url: string,
        public width: number,
        public height: number,
        public content_type: string,
        public size: number,
    ) {
        if (width <= 0) {
            throw new Error("Rendition.width must be > 0");
        }
        if (height <= 0) {
            throw new Error("Rendition.height must be > 0");
        }

        switch (content_type) {
        case "image/jpeg":
        case "image/png":
        case "image/gif":
            break;
        default:
            throw new Error("Rendition.content_type not recognized");
        }

        /*
        if (size <= 0) {
            throw new Error("Rendition.size must be > 0");
        }
        */
    }
}
