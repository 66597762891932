import * as _ from "lodash";
import ScheduledMessage from "./ScheduledMessage";

export default class List {
    public static is(name: string): boolean {
        return /^hosts\/\d+\/galleries\/\d+\/lists\/\w+$/.test(name);
    }

    public static fromData(data: any): List {
        if (!data) {
            throw new Error("List.fromData requires data");
        }

        if (!_.isString(data.id)) {
            throw new Error("List.fromData data.id must be a string");
        }

        if (!_.isUndefined(data.gallery_id) && !_.isNumber(data.gallery_id)) {
            throw new Error("List.fromData data.gallery_id should be a number");
        }

        if (!_.isUndefined(data.create_time) && !_.isString(data.create_time)) {
            throw new Error("List.fromData data.create_time must be a string");
        }

        if (!_.isUndefined && !_.isBoolean(data.default_all_guests)) {
            throw new Error("List.fromData data.default_all_guests should be a boolean");
        }

        if (!_.isUndefined(data.display_name) && !_.isString(data.display_name)) {
            throw new Error("List.fromData data.display_name should be a string");
        }

        return new List(
            data.id,
            data.gallery_id,
            data.create_time && new Date(data.create_time),
            data.display_name,
            data.default_all_guests,
            _.map(data.scheduled_messages, ScheduledMessage.fromData),
        );
    }

    constructor (
        public id: string,
        public gallery_id?: number,
        public create_time?: Date,
        public display_name?: string,
        public default_all_guests?: boolean,
        public scheduled_messages?: ScheduledMessage[],
    ) {}
}
