import * as _ from "lodash";
import API from "../api";
import Host from "../types/Host";
import Gallery from "../types/Gallery";
import Guest from "../types/Guest";
import List from "../types/List";
import ScheduledMessage from "../types/ScheduledMessage";
import Tac from "../types/Tac";
import Img from "../types/Img";
import Admin from "../types/Admin";
import { HostsQuery } from "../types/HostsQuery";
import { Product } from "../types/Product";
import {
    MaybeAPIErr,
    MaybeGallery,
    MaybeGuest,
    CreateHostRequest,
    LoginRequest,
    CreateGalleryRequest,
    ChangePasswordRequest,
    CreateGuestRequest,
    CreateListRequest,
    CreateScheduledMessageRequest,
    CreateTacRequest,
    GrantDiscountRequest,
    GuestResponse,
    ListResponse,
    ScheduledMessageResponse,
    PaymentIntent,
    CoHost
} from "../api";

export interface HostsMap {
    [name: string]: Host;
}

export interface GalleriesMap {
    [name: string]: Gallery;
}

export interface GuestsMap {
    [name: string]: Guest;
}

export interface ListsMap {
    [id: string]: List;
}

export interface ScheduledMessagesMap {
    [id: string]: ScheduledMessage;
}

export interface TacsMap {
    [name: string]: Tac;
}

export interface ProductMap {
    [id: string]: Product;
}

export interface Patch {
    auth?: string;
    host?: Host;
    hosts?: Host[];
    galleries?: Gallery[];
    guests?: Guest[];
    lists?: List[];
    tacs?: Tac[];
}

export default class World {
    public listeners: (() => void)[];
    public auth: string | null;
    public host: Host | null;
    public guest?: Guest;
    public hosts?: HostsMap;
    public galleries: GalleriesMap;
    public guests: GuestsMap;
    public lists: ListsMap;
    public scheduled_messages: ScheduledMessagesMap;
    public tacs: TacsMap;
    public newTacs: string[]; // tac.name
    public products: ProductMap;
    private short_domain: string;

    constructor(
        private api: API,
        data: any,
    ) {
        this.hosts = {};
        this.galleries = {};
        this.guests = {};
        this.lists = {};
        this.scheduled_messages = {};
        this.tacs = {};
        this.newTacs = [];
        this.products = data.products;
        this.listeners = [];

		let cookies = {
			oidc: "",
			host: "",
		};
		if (typeof window !== "undefined") {
			cookies = Object.fromEntries(document.cookie.split('; ').map(v=>v.split(/=(.*)/s).map(decodeURIComponent)));
		}

        if (data.auth) {
            this.auth = data.auth;
		} else if (cookies.oidc && cookies.host) {
			this.auth = cookies.oidc;
			localStorage.auth = cookies.oidc;
			let host = Host.fromData(JSON.parse(cookies.host));
			this.host = host;
        } else if (typeof window !== "undefined" && localStorage.auth) {
            this.auth = localStorage.auth;
		}

        if (data.host) {
            this.host = Host.fromData(data.host);
        } else if (!this.host && typeof window !== "undefined" && localStorage.host) {
            this.host = Host.fromData(JSON.parse(localStorage.host));
		}

        if (this.host && this.auth) {
            this.getHost(this.host.name);
		}

        if (data.gallery) {
           this.galleries[data.gallery.name] = Gallery.fromData(data.gallery);
        }

        if (data.guest) {
            this.guest = Guest.fromData(data.guest);
        }

        if (typeof window !== "undefined" && localStorage.galleries) {
            const galleries = JSON.parse(localStorage.galleries);
            _.each(galleries, (galleryData) => {
                const gallery = Gallery.fromData(galleryData);

                this.galleries[gallery.name] = gallery;
            });
        }
        if (data.galleries) {
            _.each(data.galleries, (galleryData) => {
                const gallery = Gallery.fromData(galleryData);

                this.galleries[gallery.name] = gallery;
            });
            if (typeof window !== "undefined") {
                localStorage.galleries = JSON.stringify(this.galleries);
            }
        }

        if (data.guests) {
            _.each(data.guests, (guestData) => {
                const guest = Guest.fromData(guestData);
                this.guests[guest.name] = guest;
            });
        }

        if (data.tacs) {
            _.each(data.tacs, (tacData) => {
                try {
                    const tac = Tac.fromData(tacData);
                    this.tacs[tac.name] = tac;
                } catch(err) {
                    console.log(`Tac.fromData: ${err.message}`);
                }
            });
        }

        if (data.short_domain) {
            this.short_domain = data.short_domain;
        }
    }

    private apply(patch: Patch) {
        if (_.has(patch, "host")) {
            this.host = patch.host;
            if (typeof window !== "undefined") {
                localStorage.host = JSON.stringify(patch.host)
            }
			_.each(this.host.galleries, (gallery) => {
				this.galleries[gallery.name] = gallery;
			});
        }
        if (_.has(patch, "auth")) {
            this.auth = patch.auth;
            if (typeof window !== "undefined") {
                localStorage.auth = patch.auth;
            }
        }
        if (_.has(patch, "hosts")) {
            _.each(patch.hosts, (host) => {
                _.each(host.galleries, (gallery) => {
                    this.galleries[gallery.name] = gallery;
                });
                delete host.galleries;
                this.hosts[host.name] = host;
            });
        }
        if (_.has(patch, "galleries")) {
            _.each(patch.galleries, (gallery) => {
                // this ovewrites existing galleries
                this.galleries[gallery.name] = gallery;
            });
            if (typeof window !== "undefined") {
                localStorage.galleries = JSON.stringify(this.galleries);
            }
        }
        if (_.has(patch, "guests")) {
            _.each(patch.guests, (guest) => {
                // ovewrites existing guest
                this.guests[guest.name] = guest;
            });
            if (typeof window !== "undefined") {
                localStorage.guests = JSON.stringify(this.guests);
            }
        }
        if (_.has(patch, "lists")) {
            _.each(patch.lists, (list) => {
                _.each(list.scheduled_messages, (sm) => {
                    // overwrites existing scheduled_message
                    this.scheduled_messages[sm.id] = sm;
                });
                delete list.scheduled_messages;
                // overwrites existing list
                this.lists[list.id] = list;
            });
        }
        if (_.has(patch, "tacs")) {
            _.each(patch.tacs, (tac) => {
                // ovewrites existing tac
                this.tacs[tac.name] = tac;
                if (!this.guests[tac.guest()]) {
                    this.getGuest(tac.guest());
                }
            })
        }

        _.each(this.listeners, (cb) => cb());
    }

    // returns unlisten function
    public listen(callback: () => void): () => void {
        this.listeners.push(callback);

        return () => {
            _.each(this.listeners, (cb, i) => {
                if (cb === callback) {
                    this.listeners.splice(i, 1);
                }
            });
        };
    }

    public async register(req: CreateHostRequest): Promise<MaybeAPIErr> {
        const [host, err] = await this.api.register(req);
        if (err) {
            return err;
        }

        const auth = host.password;
        delete host.password;
        this.apply({
            auth,
            host,
        });

        return null;
    }

	public async logAd(action: string): Promise<void> {
		await this.api.logAd(action);
	}

    public async login(req: LoginRequest): Promise<MaybeAPIErr> {
        const [host, err] = await this.api.login(req);
        if (err) {
            return err;
        }

        const auth = host.password;
        const galleries = host.galleries;
        delete host.password;
        delete host.galleries;
        this.apply({
            auth,
            host,
            galleries,
        });

        return null;
    }

    public async adminOAuth(id_token: string): Promise<[Admin | null, MaybeAPIErr]> {
        const [admin, err] = await this.api.oauthAdmin(id_token);
        if (err) {
            return [null, err];
        }

        this.auth = admin.password;

        return [admin, null];
    }

    public async discountSelf(code: string): Promise<MaybeAPIErr> {
        const err = await this.api.discountSelf(code, this.auth);

        if (err && err.status == 401) {
            await this.logout();
        }

        return err;
    }

    public async grantDiscount(req: GrantDiscountRequest): Promise<MaybeAPIErr> {
        const err = await this.api.grantDiscount(req, this.auth);

        if (err && err.status == 401) {
            await this.logout();
        }

        return err;
    }

    public async fillPool(quantity: number): Promise<[string[], MaybeAPIErr]> {
        return await this.api.fillPool(quantity, this.auth);
    }

    public async listPool(): Promise<[string[], MaybeAPIErr]> {
        return await this.api.listPool(this.auth);
    }

    public async deleteNumber(phone_number: string): Promise<MaybeAPIErr> {
        const err = await this.api.deleteNumber(`numbers/${phone_number}`, this.auth);
        if (err) {
            return err;
        }

        _.each(this.galleries, (gallery) => {
            if (gallery.phone === phone_number) {
                gallery.phone = "";
                this.apply({
                    galleries: [gallery],
                });
            }
        });

        return null;
    }

    public async releaseNumber(phone_number: string): Promise<MaybeAPIErr> {
        const err = await this.api.releaseNumber(`numbers/${phone_number}`, this.auth);
        if (err) {
            return err;
        }

        _.each(this.galleries, (gallery) => {
            if (gallery.phone === phone_number) {
                gallery.phone = "";
                this.apply({
                    galleries: [gallery],
                });
            }
        });
    }

    public async openGallery(gallery_name: string): Promise<MaybeAPIErr> {
        const [gallery, err] =  await this.api.openGallery(gallery_name, this.auth);
        if (err) {
            return err;
        }

        this.apply({
            galleries: [gallery],
        });
    }

    public async facebookLogin(): Promise<null | Error> {
        let tkn;

        try {
            tkn = await new Promise<string>((resolve, reject) => {
                const scopeConfig = {
                    scope: "email",
                    return_scopes: true,
                };

            });
        } catch (e) {
            return e;
        }

        const [host, err] = await this.api.facebookOAuth(tkn, false);
        if (err) {
            return new Error(err.message);
        }

        const auth =host.password;
        const galleries = host.galleries;
        delete host.password;
        delete host.galleries;
        this.apply({
            auth,
            host,
            galleries,
        });
    }

    public async resetPassword(email: string): Promise<MaybeAPIErr> {
        return await this.api.resetPassword(email);
    }

    public async changePassword(req: ChangePasswordRequest): Promise<MaybeAPIErr> {
        const err = await this.api.changePassword(req);
        if (err) {
            return err;
        }
        const err2 = await this.login(req);
        if (err2) {
            return err2;
        }
        return null;
    }

    public async logout(): Promise<void> {
        localStorage.clear();
        location.href = "/dashboard/login";
        await this.api.logout();
    }

    public async getHost(name: string): Promise<MaybeAPIErr> {
        const [host, err] = await this.api.getHost(name, this.auth);
        if (err) {
            if (err.status == 401) {
                await this.logout();
            }

            return err;
        }
        this.apply({
            host: host,
        });
    }

    public async updateHost(
        name: string,
        patch: any,
    ): Promise<MaybeAPIErr> {
        const [host, err] = await this.api.updateHost(name, this.auth, patch);
        if (err) {
            if (err.status == 401) {
                await this.logout();
            }
            return err;
        }
        if (this.host) {
            this.apply({
                host,
            });
        }
    }

    public async convertGallery(
        gallery_name: string,
        product_id: string,
        stripe_token: string,
        price: number,
    ): Promise<MaybeAPIErr> {
        const err = await this.api.convertGallery(
            gallery_name,
            product_id,
            stripe_token,
            price,
            this.auth,
        );

        if (err) {
            if (err.status == 401) {
                await this.logout();
            }
            return err;
        }

        const gallery = this.galleries[gallery_name];
        this.apply({
            galleries: [gallery],
        });

        return null;
    }

    public async createPaymentIntent(): Promise<[PaymentIntent|null, MaybeAPIErr]> {
      const [pi, err] =  await this.api.createPaymentIntent(
        this.auth,
      );

      if (err && err.status == 401) {
          await this.logout();
      }

      return [pi, err];
    }

    public async getUploadURL(): Promise<[string|null, MaybeAPIErr]> {
        return await this.api.uploadURL();
    }

    public async readSelfGuest(token: string): Promise<[MaybeGallery, MaybeGuest, MaybeAPIErr]> {
        const [gallery, guest, err] = await this.api.readSelfGuest(token);

        if (err) {
            return [null, null, err];
        }

        this.apply({
            guests: guest ? [guest] : [],
            galleries: gallery ? [gallery] : [],
        });

        return [gallery, guest, null];
    }

    public async newGallery(
        host_name: string,
        req: CreateGalleryRequest,
        payment_intent_id?: string,
    ): Promise<[Gallery, MaybeAPIErr]> {
        const [gallery, err] = await this.api.createGallery(
            host_name,
            req,
            payment_intent_id,
            this.auth,
        );
        if (err) {
            if (err.status == 401) {
                await this.logout();
            }
            return [null, err];
        }
        this.apply({
            galleries: [gallery],
        });
        return [gallery, null];
    }

    public async getGallery(
        name: string,
    ): Promise<[string, MaybeAPIErr]> {
        const [gallery, err] = await this.api.getGallery(name, this.auth);
        if (err) {
            if (err.status == 401 && this.auth) {
                await this.logout();
            }
            return ["", err];
        }
        this.apply({
            galleries: [gallery],
        });

        return [gallery.name, null];
    }

    public async updateGallery(
        name: string,
        patch: any,
    ): Promise<MaybeAPIErr> {
        const [gallery, err] = await this.api.updateGallery(name, this.auth, patch);
        if (err) {
            if (err.status == 401) {
                await this.logout();
            }
            return err;
        }
        this.apply({
            galleries: [gallery],
        });

        return null;
    }

    public async allowFeatured(
        gallery_id: number,
        permission_granted: boolean,
        answers: string,
    ): Promise<MaybeAPIErr> {
        const err = await this.api.allowFeatured(
            gallery_id,
            permission_granted,
            answers,
            this.auth,
        );

        return err;
    }

    public async createGuest(
        gallery_name: string,
        data: CreateGuestRequest,
        // either host token or gallery access_token for self-creation
        auth?: string,
    ): Promise<GuestResponse> {
        const [guest, err] = await this.api.createGuest(
            gallery_name,
            data,
            auth || this.auth
        );
        if (err) {
            if (err.status == 401) {
                await this.logout();
            }
            return [null, err];
        }

        this.guests[guest.name] = guest;

        return [guest, null];
    }

    public async createList(
        data: CreateListRequest,
    ): Promise<ListResponse> {
        const [list, err] = await this.api.createList(
            data,
            this.auth,
        );
        if (err) {
            if (err.status == 401) {
                await this.logout();
            }
            return [null, err];
        }
        this.lists[list.id] = list;

        return [list, null];
    }

    public async createScheduledMessage(
        data: CreateScheduledMessageRequest,
    ): Promise<ScheduledMessageResponse> {
        const [sm, err] = await this.api.createScheduledMessage(
            data,
            this.auth,
        );
        if (err) {
            if (err.status == 401) {
                await this.logout();
            }
            return [null, err];
        }
        this.scheduled_messages[sm.id] = sm;

        return [sm, null];
    }

    public async getGuest(
        name: string,
    ): Promise<MaybeAPIErr> {
        const [guest, err] = await this.api.getGuest(name, this.auth);
        if (err) {
            if (err.status == 401) {
                await this.logout();
            }
            return err;
        }
        this.apply({
            guests: [guest],
        });

        return null;
    }

    public async archive(
        gallery_name: string,
    ): Promise<[string, MaybeAPIErr]> {
        const [archive, err] = await this.api.createArchive(gallery_name, this.auth);
        if (err) {
            if (err.status == 401) {
                await this.logout();
            }
            return ["", err];
        }
        return [archive.url, null];
    }

    public async fetchHosts(): Promise<[Host[] | null, MaybeAPIErr]>{
        const resp = await this.api.listHosts(10000, this.auth);
        const hosts = resp[0];
        const err = resp[2];

        if (err) {
            if (err.status == 401) {
                await this.logout();
            }
            return [null, err];
        }

        this.apply({
            hosts,
        });

        return [hosts, null];
    }

    public async searchHosts(query: HostsQuery): Promise<[Host[] | null, string, MaybeAPIErr]>{
        const [hosts, nextPageTkn, err] = await this.api.searchHosts(this.auth, query);

        if (err) {
            if (err.status == 401) {
                await this.logout();
            }
            return [null, "", err];
        }

        return [hosts, nextPageTkn, null];
    }

    public listGalleryTacs(gallery_name: string): Tac[] {
        return _.filter(this.tacs, (tac) => _.startsWith(tac.name, gallery_name));
    }

    public listGalleryGuests(gallery_name: string): Guest[] {
        return _.filter(this.guests, (guest) => _.startsWith(guest.name, gallery_name));
    }

    public async fetchAllLists(gallery_id: number): Promise<[List[] | null, MaybeAPIErr]>{
        const [lists, err] = await this.api.listLists(gallery_id, this.auth);
        if (err) {
            if (err.status == 401) {
                await this.logout();
            }
            return [null, err];
        }

        this.apply({
            lists,
        });

        return [lists, null];
    }

    // TODO don't refetch parts of history that have been fetched
    public fetchAllTacs(gallery_name: string): Promise<void> {
        const getTacs = async (nextPageTkn?: string): Promise<void> => {
            const guest_name = gallery_name + "/guests/0";
            const [tacs, cursor, err] = await this.api.listTacs(guest_name, 1000, this.auth, nextPageTkn);
            if (err) {
                // TODO report error event
                console.log(`fetch tacs: ${err.message}`);
                return;
            }
            this.apply({
                tacs,
            });
            if (cursor && tacs.length) {
                return getTacs(cursor);
            }
        };

        return getTacs();
    }

    public async fetchAllGuests(gallery_name: string): Promise<[Guest[] | null, MaybeAPIErr]> {
        const [guests, cursor, err] = await this.api.listGuests(gallery_name, 1000, this.auth);
        if (err) {
            return [null, err];
        }

        this.apply({
            guests,
        });
 
        return [guests, null];
    }

    public async fetchAllGalleries(host_name: string): Promise<[Gallery[] | null, MaybeAPIErr]> {
        const [galleries, cursor, err] = await this.api.listGalleries(host_name, 1000, this.auth);
        if (err) {
            return [null, err];
        }

        this.galleries = {};
        this.apply({
            galleries,
        });

        return [galleries, null];
    }

    // Subscribe to all gallery changes and apply them. All the magic of reusing
    // subscriptions and reconnecting happens at a lower level. These are full
    // entities, not diffs.
    public watch(gallery_name: string, gallery_access_token: string): () => void {
        let canceled = false;
        let unsub = () => {};
        const unwatch = () => {
            canceled = true;
            unsub();
        };

        this.api.subscribe(gallery_name, gallery_access_token, (patch: any) => {
            try {
                if (Host.is(patch.name)) {
                    const host = Host.fromData(patch);

                    this.apply({ host });

                    return;
                }

                if (Gallery.is(patch.name)) {
                    const g = Gallery.fromData(patch);
                    const old = this.galleries[patch.name];

                    this.apply({
                        galleries: [g],
                    });

                    return;
                }

                if (Guest.is(patch.name)) {
                    const g = Guest.fromData(patch);
 
                    this.apply({
                        guests: [g],
                    });

                    return;
                }

                if (Tac.is(patch.name)) {
                    const t = Tac.fromData(patch);
                    const old = this.tacs[patch.name];

                    this.apply({
                        tacs: [t],
                    });

                    if (!old) {
                        this.newTacs.push(patch.name);
                    }

                    return;
                }

                throw new Error("unknown patch: " + JSON.stringify(patch));
            } catch (err) {
                console.log(`Realtime Error: ${err.message}`);
                // TODO report
            }
        })
        .then((_unsub) => {
            if (canceled) {
                unsub();
                return;
            }
            unsub = _unsub;
        });

        return unwatch;
    }

    public async createTac(galleryID: number, image_url?: string, note?: string, guest?: Guest) {
        const tac: CreateTacRequest = {};
        const guestName = guest ? guest.name : this.guest.name;

        if (image_url) {
            tac.image = new Img({
                url: image_url,
                width: 0,
                height: 0,
                content_type: "",
                size: 0,
            });
        }
        if (note) {
            tac.note = {
                text: note,
            }
        }

        return await this.api.createTac(guestName, tac, galleryID);
    }

    public async deleteTac(name: string): Promise<MaybeAPIErr> {
        const [updated, err] = await this.api.updateTac(name, this.auth, {
            state: "DELETED",
        });
        if (err) {
            if (err.status == 401) {
                await this.logout();
            }
            return err;
        }
        this.apply({
            tacs: [updated],
        });
        return null;
    }

    public async approveTac(name: string): Promise<MaybeAPIErr> {
        const [updated, err] = await this.api.updateTac(name, this.auth, {
            state: "APPROVED",
        });
        if (err) {
            if (err.status == 401) {
                await this.logout();
            }
            return err;
        }
        this.apply({
            tacs: [updated],
        });
        return null;
    }

    public async addCoHost(name: string, email: string): Promise<MaybeAPIErr> {
        const req = {email};
        const [gallery, err] = await this.api.addCoHost(name, req, this.auth);

        return err;
    }

    public async removeCoHost(name: string, email: string): Promise<MaybeAPIErr> {
        const err = this.api.removeCoHost(name, email, this.auth);
        return err;
    }

    public async listCoHosts(name: string): Promise<CoHost[]> {
        const [cohosts, err] = await this.api.listCoHosts(name, this.auth);
        if (err) {
            // TODO handle error
            console.log(err);
            return [];
        }
        return cohosts;
    }

    public async blockGuest(name: string): Promise<MaybeAPIErr> {
        const [updated, err] = await this.api.updateGuest(name, this.auth, {
            blocked: true,
        });
        if (err) {
            if (err.status == 401) {
                await this.logout();
            }
            return err;
        }
        this.apply({
            guests: [updated],
        });
    }

    public shortURL(access_token: string): string {
        return `http://${this.short_domain}/${access_token}`;
    }

    public uploadURL(access_token: string): string {
        return `${location.protocol}//${location.host}/up/${access_token}`;
    }

    public origin(): string {
        if (location.origin) {
            return location.origin;
        }

        return `${location.protocol}//${location.host}`;
    }

    public hasTrialGallery(): boolean {
        return this.host && _.some(this.galleries, (gallery) => {
            return gallery.isTrial();
        });
    }
}
