import * as _ from "lodash";
import Rendition from "./Rendition";
import Analysis from "./Analysis";

export default class Img {
    public static fromData(data: any) {
        let optimized, analysis;

        if (!data) {
            throw new Error("Img.fromData requires data");
        }

        if (!data.original) {
            throw new Error("Img.fromData requires data.original");
        }

        if (_.isArray(data.optimized)) {
            optimized = _.map(data.optimized, Rendition.fromData);
        }

        if (data.analysis) {
            analysis = Analysis.fromData(data.analysis);
        }

        return new Img(
            Rendition.fromData(data.original),
            optimized,
            analysis,
        );
    }

    public optimized?: Rendition[];
    public analysis?: Analysis;

    constructor(
        public original: Rendition,
        optimized?: Rendition[],
        analysis?: Analysis,
    ) {
        if (optimized) this.optimized = optimized;
        if (analysis) this.analysis = analysis;
    }

    public allRenditionsSorted() {
        let renditions: Rendition[] = [];

        if (this.optimized) {
            renditions = _.concat(renditions, this.optimized);
        }

        renditions.push(this.original);

        return _.sortBy(renditions, ['width', 'size']);
    }
}
