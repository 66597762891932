import _ from "lodash";

enum Likelihood {
    unknown = 0,
    very_unlikely,
    unlikely,
    maybe,
    likely,
    very_likely,
}

const toLikelihood = (n: number): Likelihood => {
    switch (n) {
    case 0:
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
        return n;
    }

    throw new Error("Likelihood must be an integer 0 - 5");
}

const toScore = (s: string|number): number => {
    if (typeof s === "number") {
        return s;
    }
    switch (s) {
    case "VERY_UNLIKELY":
        return 1;
    case "UNLIKELY":
        return 2;
    case "MAYBE":
        return 3;
    case "LIKELY":
        return 4;
    case "VERY_LIKELY":
        return 5;
    default:
        return 0;
    }
}

export default class Analysis {
    public adult: Likelihood;
    public spoof: Likelihood;
    public violence: Likelihood;
    public medical: Likelihood;

    public static fromData(data: any): Analysis {
        if (!data) {
            throw new Error("Analysis.fromData requires data");
        }

        if (!_.isNumber(data.adult) && !_.isString(data.adult)) {
            throw new Error("Analysis.adult must be a number or string");
        }

        if (!_.isNumber(data.spoof) && !_.isString(data.spoof)) {
            throw new Error("Analysis.spoof must be a number or string");
        }
        
        if (!_.isNumber(data.violence) && !_.isString(data.violence)) {
            throw new Error("Analysis.violence must be a number or string");
        }

        if (!_.isNumber(data.medical) && !_.isString(data.medical)) {
            throw new Error("Data.medical must be a number or string");
        }

        return new Analysis(
            toScore(data.adult),
            toScore(data.spoof),
            toScore(data.violence),
            toScore(data.medical),
        );
    }

    constructor(
        adult: number,
        spoof: number,
        violence: number,
        medical: number,
    ) {
        this.adult = toLikelihood(adult);
        this.spoof = toLikelihood(spoof);
        this.violence = toLikelihood(violence);
        this.medical = toLikelihood(medical);
    }
}
