import * as _ from "lodash";
import Match from "./Match";

export default class Vulgar {
    public static fromData(data: any) {
        if (!data) {
            throw new Error("Vulgar data is required");
        }

        if (!_.isUndefined(data.matches) && !_.isArray(data.matches)) {
            throw new Error("Vulgar.matches must be an array")
        }

        return new Vulgar(
            _.map(data.matches, Match.fromData),
        );
    }

  constructor(
      public readonly matches: Match[],
  ) {}
}
