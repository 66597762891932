import * as _ from "lodash";

export default class Sentiment {
    public static fromData(data: any) {
        if (!data) {
            throw new Error("Sentiment data is required");
        }

        if (!_.isUndefined(data.score) && !_.isNumber(data.score)) {
            throw new Error("Sentiment.score should be a number")
        }
        if (!_.isUndefined(data.magnitude) && !_.isNumber(data.magnitude)) {
            throw new Error("Sentiment.magnitude should be a number")
        }

        return new Sentiment(
            data.score,
            data.magnitude,
        )
    }

  constructor(
      public readonly score?: number,
      public readonly magnitude?: number,
  ) {
      this.score = _.isUndefined(score) ? 0 : score;
      this.magnitude = _.isUndefined(magnitude) ? 0 : magnitude;
  }
}
